import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const baseUrl = environment.baseUrl;
const toolApi = environment.toolApi;

@Injectable({
  providedIn: 'root'
})
export class BenefitsService {

  constructor(private _http: HttpClient) { }

  getBenefitPeriod(): Observable<any>{
    return this._http.get(`${baseUrl}/quote/UI/references/benefitPeriod`);
  }

  getOccupationType(): Observable<any>{
    return this._http.get(`${baseUrl}/quote/UI/references/occupationType`);
  }

  getTPDCalcPeriod(): Observable<any>{
    return this._http.get(`${baseUrl}/quote/UI/references/tpd/calcPeriod`);
  }

  getTraumaCalcPeriod(): Observable<any>{
    return this._http.get(`${baseUrl}/quote/UI/references/trauma/calcPeriod`);
  }

  getLifeCalcPeriod(): Observable<any>{
    return this._http.get(`${baseUrl}/quote/UI/references/life/calcPeriod`);
  }

  getHealthExcess(): Observable<any>{
    return this._http.get(`${baseUrl}/quote/UI/references/health/excess`);
  }

  getProductsByBenefit(benefitId): Observable<any>{
    return this._http.post(`${baseUrl}/quote/GetProductGroupsByBenefitId`, {
      benefitId
    });
  }

  getBenefitPeriodValues(): Observable<any>{
    return this._http.get(`${baseUrl}/quote/UI/references/benefitPeriod/values`);
  }

  getSpecialistsTestExcess(): Observable<any>{
    return this._http.get(`${baseUrl}/quote/UI/references/health/specialistsTestExcess`);
  }

  getProviders(): Observable<any>{
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'userToken': `236cf4b751824c989132d7bba6b11978`
    })
    return this._http.get(`${toolApi}/insurance/v3/providers`, { headers });
  }
}
